<template>
	<v-dialog v-model="dialog" max-width="500px" persistent>
		<v-card>
			<v-card-title>
				<span class="text-body-1 primary--text">Editar PEP</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					<validation-alert :validationErrors="validationErrors" />
					<v-form ref="form" v-model="isValidFormDialog" lazy-validation>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.code"
									:rules="codeRules"
									label="PEP"
									required
									disabled
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="editedItem.pep_description"
									label="Descripción"
									required
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-autocomplete
									v-model="editedItem.responsible_id"
									:items="users"
									:rules="responsibleRules"
									item-text="name"
									item-value="id"
									label="Responsable"
									required
								></v-autocomplete>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-switch v-model="editedItem.active" label="Estado"></v-switch>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey" text @click="close()"> Cancelar </v-btn>
				<v-btn color="blue darken-1" text @click="save()" :loading="isLoading" :disabled="isLoading">
					Guardar
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import { $EventBus } from '@/main';
export default {
	name: 'EditManagementDialog',

	props: {
		pep: Object,
	},

	data: () => ({
		dialog: false,
		isLoading: false,
		isValidFormDialog: false,
		codeRules: [(v) => !!v || 'Campo requerido'],
    responsibleRules: [(v) => !!v || "Campo requerido"],
		editedItem: {
			id: '',
			code: '',
			active: true,
			pep_description: '',
			responsible_id: '',
		},
		defaultItem: {
			id: '',
			code: '',
			pep_description: '',
			responsible_id: '',
			active: true,
		},
	}),

	computed: {
		validationErrors() {
			return this.$store.getters['peps/getValidationErrors'];
		},
		users() {
			return this.$store.getters['users/getUsers'];
		},
	},

	watch: {
		pep(val) {
			if (val && val.id) {
				this.dialog = true;
				this.editedItem = val;
				if(this.editedItem.responsible_id && isNaN(this.editedItem.responsible_id)){
					this.editedItem = Object.assign(this.editedItem, {responsible_id: this.editedItem.responsible_id.id });
				}
			}
		},
		dialog(val) {
			val || this.close();
      if (val) this.$store.dispatch('users/getUsers', { active: 1 });
		},
	},

	methods: {
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				this.$refs.form.reset();
			});
		},

		showSnack(color = '', text = '') {
			$EventBus.$emit('showSnack', { color, text });
		},

		async save() {
			if (this.$refs.form.validate()) {
				this.isLoading = true;
				try {
					await this.$store.dispatch('peps/updatePep', this.editedItem);
					this.showSnack('green', 'PEP actualizado');
					this.$emit('resetFilter');
					this.close();
				} catch (error) {}
				this.isLoading = false;
			}
		},
	},
	components: {
		ValidationAlert,
	},
};
</script>
